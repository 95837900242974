body{
    background-color: #f6fcff;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", "sans-serif";
}

input::placeholder, textarea::placeholder{
        font-family: "Montserrat", "sans-serif";
        color: #aaa;
}

input:focus, textarea:focus{
  outline: none;
}

a, nav button{
  text-decoration: none;
  background-color:  transparent;
  border: none;
  color: black;
  transition: .4s;
  font-size: 1em;
  cursor: pointer;
  font-family: "Montserrat", "sans-serif";
} 

a:hover, nav button:hover{
  color: #bbb;
}

/* form style */
form{
  max-width: 40%;
  margin: 0 auto;
  
}

label{
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

label span{
  margin-bottom: .3em;
  font-weight: bold;
  text-align: left;
}

input, textarea {
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: .8em 0;
  background-color: transparent;
}

.btn{
  background-color: #1a8918;
  color: white;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  width: 120px;
  font-weight: bold;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
}

.btn.btn-dark{
  background-color: black;
  border-radius: 0;
}

.btn.btn-outline {
  background-color: transparent;
  border-radius: 0;
  color: black;
  border: 1px solid black;
  padding: 7px 30px;
}

.btn:hover{
  background-color: #0f730c;
  color: white;
}

.btn.btn-outline:hover {
  background-color: black;
}

.btn.btn-danger:hover{
  background-color: red;
}

button[disable]{
  background-color: #aaa;
}

.container .error{
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 5px;
}